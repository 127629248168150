







































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import GlobalMixins from '../mixins';

@Component
export default class SelectFavorites extends GlobalMixins {
    created() {
        this.checkFinished();
        
        this.$store.dispatch('setEditing', true);
    }
}
