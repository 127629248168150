

























import { Component, Vue } from 'vue-property-decorator';
import GlobalMixins from '../mixins';

@Component
export default class Tooltips extends GlobalMixins {
    tooltipMoreInfo = true
    tooltipParties = false

    deactivateIntro() {
        this.$store.state.showIntro = false;
    }
}
