import Vue from 'vue'
import Vuex from 'vuex'

import mutations from './mutations'
import getters from './getters'
import actions from './actions'

import * as Data from '@/data/data.json'

function shuffle(array: any) {
	var currentIndex = array.length, temporaryValue, randomIndex;
	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
	  // Pick a remaining element...
	  randomIndex = Math.floor(Math.random() * currentIndex);
	  currentIndex -= 1;
	  // And swap it with the current element.
	  temporaryValue = array[currentIndex];
	  array[currentIndex] = array[randomIndex];
	  array[randomIndex] = temporaryValue;
	}
	return array;
}

// const regularParties = (Data as any).default.parties
const parties = shuffle((Data as any).default.parties)

import { IEntity } from '@/interfaces'

Vue.use(Vuex)

export interface State {
	entity: IEntity | null
	backupEntity: IEntity | null
	userAnswers: any[],
	privacyStatementSelected: boolean,
	parties: any,
	favoriteQuestions: any[]
	score: any[],
	isEditing: boolean,
	showIntro: boolean,
	reachedQuestion: number,
	questionsFinished: boolean,
}

const state: State = {
	entity: null,
	backupEntity: null,
	userAnswers: [],
	privacyStatementSelected: false,
	parties,
	favoriteQuestions: [],
	score: [],
	isEditing: false,
	showIntro: true,
	reachedQuestion: 0,
	questionsFinished: false
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
})
