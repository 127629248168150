









































































































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import GlobalMixins from '../mixins'

@Component
export default class Results extends GlobalMixins {
	name = 'Results'
	topThree: any = []
	partyResults: any = []
	maximumScore: any = this.allQuestions.length + this.favoriteQuestions.length
	userAnswers: any = this.$store.state.userAnswers

	get parties() {
		return this.$store.getters.parties()
	}

	get favoriteQuestions() {
		return this.$store.getters.favoritesQuestions()
	}

	compare(a: any, b: any) {
		if ( a.score > b.score ){
			return -1;
		}
		if ( a.score < b.score ){
			return 1;
		}
		return 0;
	}

	// mounted() {
	// 	let enalyzer = document.createElement('script');    
	// 	enalyzer.setAttribute("type", "text/javascript")
	// 	enalyzer.setAttribute("id", "enalyzer-embed")
	// 	enalyzer.setAttribute("data-env", "https://surveys.enalyzer.com")
	// 	enalyzer.setAttribute("data-embedid", "q2td6r2h")
	// 	enalyzer.setAttribute("src", "https://surveys.enalyzer.com/Scripts/websitecollector.js")
	// 	document.head.appendChild(enalyzer)
	// }

	created() {
		// this.$store.dispatch('setEditing', true);

		// this.checkFinished();

		this.parties.forEach((party: any, index1: number) =>  {
			// console.log('---', party.name, '---')
			var totalScore = 0;

			party.questions.forEach((question: any, index2: number) => {
				var currentScore = 0;
				
				if (question.participating == 'false') {
				currentScore = 0;
				} else {
					// Execute the following calculation if the question is a range-slider
					if (this.allQuestions[index2].type === "range-slider") {
						const options = this.allQuestions[index2].options;
						let userIndex = options.findIndex((element: any) => element.value == this.userAnswers[index2]) + 1;
						let partyIndex = options.findIndex((element: any) => element.value == question.answer) + 1;
						let totalIndex = options.length;
						let sensitivity = JSON.parse(this.allQuestions[index2].sensitivity);
						let score = 0;

						// console.log('This is a range slider with', totalIndex, 'options: ')
						// console.log('You answered with: ', this.userAnswers[index2]) 
						// console.log(party.name, 'answered with: ', question.answer)
						// console.log(party);

						// console.log('User answer index: ', userIndex);
						// console.log('Party answer index: ', partyIndex);
						// console.log('Total index: ', totalIndex);
						// console.log('Sensitivity: ', sensitivity)

						if (!sensitivity) {
						score = userIndex === partyIndex ? 1 : 0
						} else {
						let difference: number = 0

						if (userIndex === partyIndex) {
							difference = 0;
						} else if (userIndex > partyIndex) {
							difference = userIndex - partyIndex;
						} else if (userIndex < partyIndex) {
							difference = partyIndex - userIndex;
						}
						sensitivity ++;
						
						score = 1 - ((1 / sensitivity) * difference)
						}
						if (score < 0) {
						score = 0;
						}

						currentScore = score;
					} else {

						// If your answer is the same as the answer given by the party...
						if (this.userAnswers[index2] === question.answer) {
							currentScore = 1;
						}
					}

					// Check if this answer has been favorited
					if (this.favoriteQuestions.includes(this.allQuestions[index2])) {
						currentScore = currentScore * 2;
					}
				}

				// console.log('participating: ', question.participating)
				// console.log('currentScore = ', currentScore)
				totalScore += currentScore;
			});

			this.partyResults[index1] = {
				name: party.name, 
				logo: party.logo,
				score: totalScore
			}
		});

		this.partyResults.sort(this.compare);
		this.topThree = this.partyResults.splice(0, 3)
	}
}
