




import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class ContentText extends Vue {
    @Prop({ required: true })item!: any
}
