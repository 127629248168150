












































import { Component, Vue } from 'vue-property-decorator'
import GlobalMixins from '../mixins'

import Dilemma from '../components/questions/Dilemma.vue'
import MultipleChoice from '../components/questions/MultipleChoice.vue'
import RangeSlider from '../components/questions/RangeSlider.vue'
import Tooltips from '@/components/Tooltips.vue'

@Component({
	components: {
		Tooltips
	}
})
export default class Question extends GlobalMixins {
    showMoreInfo = false

    get questionType() {
		switch(this.question.type) {
			case 'range-slider':
				return RangeSlider
			case 'dilemma':
				return Dilemma
			case 'multiple-choice':
				return MultipleChoice
		}
    }

    created() {
        if (JSON.parse(this.$route.params.id) === this.dataQuestions.length) {
            this.$store.state.questionsFinished = true;
        }
        
        this.$store.dispatch('setEditing', true);
        // Update the reachedQuestion to the highest reached question (for showing the next button)
        if (this.$store.getters.reachedQuestion() > this.$route.params.id) {
            return
        } else {
            this.$store.dispatch('setReachedQuestion', this.$route.params.id);
        }

        switch(this.tape.type) {
	        case 1:
                return this.tape.width = '100px'
                break
	        case 2:
                return this.tape.width = '90px'
                break
	        case 3:
                return this.tape.width = '84px'
                break
        }
    }
}
