













import { Component, Vue } from 'vue-property-decorator'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import GlobalMixins from './mixins'

@Component({
	components: {
    AppHeader,
    AppFooter
	}
})
export default class App extends GlobalMixins {
  	appInactive = false

	get progressWidth(): number {
		if (this.$route.name === 'question') {
			return ((100 / (this.dataQuestions.length + 1) * (JSON.parse(this.$route.params.id) - 1)))
		} else if (this.$route.name === 'select-favorites') {
			return ((100 / this.dataQuestions.length) * (this.dataQuestions.length - 1))
		} else if (this.$route.name === 'results') {
			return 100
		} else {
			return 0
		}
	}

	// Give the user a warning before reloading or navigating away (loss of data)
	preventNav(event: any) {
		if (!this.$store.state.isEditing) return
		event.preventDefault()
		event.returnValue = ""
	}

	beforeMount() {
		window.addEventListener("beforeunload", this.preventNav)
	}

	beforeDestroy() {
		window.removeEventListener("beforeunload", this.preventNav);
	}

}
