import { ActionTree } from 'vuex'
import { State } from '.'

const actions: ActionTree<State, any> = {
    setAnswer({ state, commit }, payload: {index: number, answer: any}) {
        const answers = state.userAnswers 
        answers[payload.index] = payload.answer
        commit('setAnswers', answers)
    },
    favoriteQuestion({ state, commit }, payload: any) {
        const favoriteQuestions = state.favoriteQuestions
        if (!favoriteQuestions.includes(payload)) {
            if (favoriteQuestions.length < 3) {
                favoriteQuestions.push(payload)
            }
        } else {
            favoriteQuestions.splice(favoriteQuestions.indexOf(payload), 1)
        }

        commit('setFavoriteQuestion', favoriteQuestions)
    },
    prioritizeParty({ state, commit }, payload: string) {
        const parties = state.parties
        const priorityParty = parties.filter((party: any) => {
            return party.name === payload
        })[0]
        
        parties.splice(parties.indexOf(priorityParty), 1)
        parties.unshift(priorityParty)

        commit('setParties', parties)
    },
    setEditing({ state, commit }, payload: boolean) {
        commit('setEditing', payload)
    },
    setReachedQuestion({ state, commit }, payload: any) {
        commit('setReachedQuestion', payload)
    }
}

export default actions