import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Start from '@/views/Start.vue'
import Question from '@/views/Question.vue'
import Results from '@/views/Results.vue'
import SelectFavorites from '@/views/SelectFavorites.vue'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
	{
		path: '/',
		name: 'start',
		component: Start
	},
	{
		path: '/question/:id',
		name: 'question',
		component: Question
	},
	{
		path: '/results',
		name: 'results',
		component: Results
	},
	{
		path: '/select-favorites',
		name: 'select-favorites',
		component: SelectFavorites
	},
	{
		path: '/pagina-niet-gevonden',
		alias: '*',
		redirect: {
		name: 'start'
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	Vue.nextTick(() => {
		if (to.name === 'start') {
			document.title = 'Ongemakkelijke Kieswijzer'
		} else if (to.name === 'question') {
			document.title = `Ongemakkelijke Kieswijzer | Vraag ${to.params.id}`
		} else if (to.name === 'select-favorites') {
			document.title = "Ongemakkelijke Kieswijzer | Selecteer favorieten"
		} else if (to.name === 'results') {
			document.title = "Ongemakkelijke Kieswijzer | Resultaat"
		}

		window.scrollTo(0, 0);

		next()
	})
})

export default router