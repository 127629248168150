


















import GlobalMixins from '@/mixins';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MultipleChoice extends GlobalMixins {
    
    created() {
        const savedAnswer = this.$store.getters.answer(JSON.parse(this.$route.params.id) - 1)
        if (savedAnswer) {
            this.answer = savedAnswer
        }
    }
}

