























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import GlobalMixins from '../mixins';

import ContentText from '@/components/more-information/Text.vue';
import ContentQuote from '@/components/more-information/Quote.vue';
import ContentList from '@/components/more-information/List.vue';

@Component({
	components: {
        ContentText,
        ContentQuote,
        ContentList
	}
})
export default class Header extends GlobalMixins {
    showMoreInfo = false
    showParties = false
    selectedPartyName = ''
    fullUrl = 'https://www.ongemakkelijkekieswijzer.nl'
    socialShareText = 'Durf jij ook te kiezen? Doe de Ongemakkelijke Kieswijzer! Denk na over jouw keuzevrijheid, bijvoorbeeld over je eigen dood, abortus, onderwijs of liefdesleven.'

    @Watch('selectedPartyName')
    onSelectedPartyNameChange() {
        this.$store.dispatch('prioritizeParty', this.selectedPartyName);
    }

    get parties() {
        if (this.$route.name === 'question') {
            return this.$store.getters.parties(JSON.parse(this.$route.params.id) - 1)
        } else {
            return this.$store.getters.parties()
        }
    }

    get selectedParty(): any {
        if (!this.selectedPartyName) {
            return false
        }
        return this.parties.filter((party: any) => {
            return party.name === this.selectedPartyName
        })[0]
    }

    get reachedQuestion(): any {
        return this.$store.getters.reachedQuestion;
    }
    
    toggleInformation() {
        this.showMoreInfo = !this.showMoreInfo
    }
    toggleParties() {
        this.showParties = !this.showParties
    }

    getComponentByType(type: string): any {
		switch (type) {
			case 'text':
				return ContentText
				break
			case 'quote':
				return ContentQuote
				break
			case 'list':
				return ContentList
				break
		}
    }
    
    created() {
        this.selectedPartyName = this.parties[0].name
    }
}
