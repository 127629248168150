











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GlobalMixins from '@/mixins';

import VueSlider from 'vue-slider-component'

@Component({
	components: {
		VueSlider
	}
})
export default class Slider extends GlobalMixins {
    sliderValue = ''

    @Watch('sliderValue', { deep: true })
	onChange(): void {
        this.saveAnswer(this.sliderValue)
    }
    
    created() {
        const savedAnswer = this.$store.getters.answer(JSON.parse(this.$route.params.id) - 1)
        if (savedAnswer) {
            this.sliderValue = savedAnswer
            this.answer = savedAnswer
        } else {
            this.saveAnswer(this.question.options[0].value)
        }
    }
}

