import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'UA-6262141-11',
  router,
  set: [
    { field: 'anonymizeIp', value: true },
    { field: 'forceSSL', value: true }
  ]
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
