import { Vue } from 'vue-property-decorator';

import * as Data from '@/data/data.json'

export default class GlobalMixins extends Vue {
    dataQuestions: any = (Data as any).default.questions
    dataParties: any = (Data as any).default.parties
    dataNonParticipants: any = (Data as any).default.nonParticipants
    answer: any = false
    answerGiven: string = ''
    showButton: boolean = false
    tape: any = {
        type: Math.floor(Math.random() * 3) + 1,
        width: '',
        position: 36 + (Math.floor(Math.random() * 50) + 1),
        rotation: Math.floor(Math.random() * 10) + 1
    }
    validRoute: boolean = false

    get allQuestions(): any {
        return this.dataQuestions
    }
    get question(): any {
        if (this.$route.name !== 'question') return
        return this.dataQuestions[JSON.parse(this.$route.params.id) - 1]
    }
    get favoriteQuestions() {
		return this.$store.getters.favoritesQuestions()
	}

    start(): void {
        this.$router.push({ name: 'question', params:{
            id:'1'
        }})
    }
    previous(): void {
        if (this.$route.name === 'question' && this.$route.params.id === '1') {
            this.$router.push({ name: 'start' })
        } else if (this.$route.name === 'question' && this.$route.params.id !== '1') {
            this.$router.push({ name: 'question', params:{
                id: JSON.stringify(JSON.parse(this.$route.params.id) - 1)
            }})
        } else if (this.$route.name === 'select-favorites') {
            this.$router.push({ name: 'question', params:{
                id: this.dataQuestions.length
            }})
        } else if (this.$route.name === 'results') {
            this.$router.push({ name: 'select-favorites' })
        } 
    }
    // add start function
    next(): void {       
        if (this.$route.name === 'start') {
            this.$router.push({ name: 'question', params:{
                id:'1'
            }})
        } else if (this.$route.name === 'question' ) {
            if (this.dataQuestions.length === (JSON.parse(this.$route.params.id))) {
                setTimeout(() => {
                    this.$router.push({ name: 'select-favorites' })
                }, 200);
            } else {
                setTimeout(() => {
                    this.$router.push({ name: 'question', params:{
                        id: JSON.stringify(JSON.parse(this.$route.params.id) + 1)
                    }})
                }, 200);
            }
        } else if (this.$route.name === 'select-favorites') {
            setTimeout(() => {
                this.$router.push({ name: 'results' })
            }, 200);
        }
    }
    saveAnswer(answer: any): void {
        this.$store.dispatch('setAnswer', { index: (JSON.parse(this.$route.params.id) - 1), answer });
        Vue.nextTick(() => { this.answer = this.$store.getters.answer(JSON.parse(this.$route.params.id) - 1) })
    }
    favoriteQuestion(question: any) {
        this.$store.dispatch('favoriteQuestion', question);
    }
    checkFinished() {
        if (this.$store.state.questionsFinished === false) {
			this.$router.push({ name: 'start' })
		}
    }
}