








































import { Component, Vue } from 'vue-property-decorator';
import GlobalMixins from '../mixins';

@Component
export default class Footer extends GlobalMixins {

}
