import { GetterTree } from 'vuex'
import { State } from '.'

const getters: GetterTree<any, any> = {
    answer:(state: State) => (index: number) => {
        const answer = state.userAnswers[index] 
        return answer === undefined ? false : answer
    },
    favoritesQuestions:(state: State) => (index: number) => {
        const favorites = state.favoriteQuestions
        return favorites
    },
    parties:(state: State) => (index: number) => {
        if (typeof index !== 'number' || index < 0) return state.parties
        return state.parties.map((party: any) => {
            return {
                name: party.name,
                logo: party.logo,
                question: party.questions[index]
            }
        })
	},
    reachedQuestion:(state: State) => (index: number) => {
        return state.reachedQuestion
    },
    userAnswers:(state: State) => (index: number) => {
        return state.userAnswers
    }
}

export default getters