
































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import GlobalMixins from '../mixins'
import Buttons from '@/components/Buttons.vue'

@Component({
	components: {
		Buttons
	}
})
export default class Start extends GlobalMixins {
	name = 'Start'

	get parties() {
		return this.$store.state.parties
	}
}
