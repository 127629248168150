import { MutationTree } from 'vuex'
import { State } from '.'

const mutations: MutationTree<any> = {
    'setAnswers' (state: State, payload: any[]) {
		state.userAnswers = payload
	},
	'setFavoriteQuestion' (state: State, payload: any[]) {
		state.favoriteQuestions = payload
	},
	'setParties' (state: State, payload: any[]) {
		state.parties = payload
	},
	'setEditing' (state: State, payload: boolean) {
		state.isEditing = payload
	},
	'setReachedQuestion' (state: State, payload: any) {
		state.reachedQuestion = payload
	}
}

export default mutations
